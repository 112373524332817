<template>
  <div class="details-page">
    <!-- DONE:面包屑导航 -->
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span
          @click="
            $router.push(
              `/courlist?tid=${basicInfo.type_id}&tn=${basicInfo.type_name}`
            )
          "
          >{{ basicInfo.type_name ? basicInfo.type_name : "档案课程" }}</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{ courseTitle }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div class="details details-title">
        <div class="title-img">
          <img :src="basicInfo.cover_img" alt="" v-if="basicInfo.cover_img" />
        </div>
        <div class="title-info">
          <p>{{ basicInfo.course_name }}</p>
          <p>
            课时：{{ basicInfo.class_hour }}个 时长：{{
              basicInfo.video_time
            }}小时
          </p>
        </div>
        <div class="title-progress">
          <div class="progress-pro">
            <span>已学{{ courseNumInfo.study_rate }}%：</span>
            <el-progress
              :percentage="courseNumInfo.study_rate"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="progress-btns">
            <button
              class="collection"
              v-if="basicInfo.is_collection === 0"
              @click="getCollect"
            >
              &emsp;收藏
            </button>
            <button
              class="collection"
              v-if="basicInfo.is_collection === 1"
              style="background-position: 13% -40%"
              @click="cancleCollect"
            >
              &emsp;取消收藏
            </button>
            <button @click="addCart" v-if="courseNumInfo.is_buy === 0">
              加入购物车
            </button>
            <button
              class="now-see"
              @click="continuePlay"
              v-if="courseNumInfo.is_buy !== 0 && courseNumInfo.last_video_id"
            >
              继续观看
            </button>
            <button
              class="now-see"
              @click="nowPlay"
              v-if="
                courseNumInfo.is_buy !== 0 && courseNumInfo.last_video_id === 0
              "
            >
              立即观看
            </button>
          </div>
        </div>
      </div>
      <div class="details details-directory">
        <!-- <p class="directory-title">课程详情</p> -->
        <b class="course-catalog-text">课程目录</b>
        <ul>
          <!-- MARK:background-position -20%:看完 -->
          <!-- MARK:background-position  50%:看了 -->
          <!-- MARK:background-position 120%:没看 -->
          <li
            @click="toVideo(video.id)"
            v-for="video in videoList"
            :key="video.id"
            :style="{
              backgroundPositionY:
                video.finish_video === 0
                  ? '120%'
                  : video.finish_video === 1
                  ? '-20%'
                  : '50%',
            }"
          >
            <img src="../assets/images/video_player.png" alt="" />
            <span class="chapter-number">{{ video.video_name }}</span>
            <span class="chapter-name">&emsp;{{ video.title }}&nbsp;</span>
            <span class="chapter-time"
              >（{{ formatSeconds(video.video_time) }}）</span
            >
            <i
              class="chapter-nearly"
              v-if="courseNumInfo.last_video_id === video.id"
              >最近学习</i
            >
          </li>
          <li
            @click="goExam"
            :style="{
              backgroundPositionY: see === 0 ? '120%' : '-20%',
            }"
            v-show="exam"
          >
            <img src="../assets/images/exam.png" alt="" />
            <span class="chapter-exam"
              >去考试 <i class="chapter-nearly" v-show="see == 1">已考试</i>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseTitle: "",
      basicInfo: {},
      courseNumInfo: {},
      videoList: [],
      see: 0,
      exam: 0,
    };
  },
  components: {},

  computed: {},
  mounted() {
    this.getCourseDetailData();
  },
  methods: {
    getCourseDetailData() {
      const { cid, ct } = this.$route.query;
      this.courseTitle = ct;
      this.axios
        .get(
          `/coursepc/index/course_detail?type_id=${this.$route.query.tid}&course_id=${cid}`
        )
        .then((res) => {
          this.basicInfo = res.data.course_list[0];
          this.courseNumInfo = res.data.num;
          this.videoList = res.data.video_list;
          this.see = res.data.is_see;
          this.exam = res.data.is_exam;
        });
    },
    // 秒转时分秒
    formatSeconds(time) {
      let result = parseInt(time);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== "00") res += `${h}:`;
      if (m !== "00") res += `${m}:`;
      res += `${s}`;
      return res;
    },
    // 收藏
    getCollect() {
      let fd = new FormData();
      fd.append("id", this.basicInfo.id);
      fd.append("is_collection", 1);
      this.axios.post("/coursepc/index/change_collection", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("收藏成功");
          this.getCourseDetailData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 取消收藏
    cancleCollect() {
      // chuan 0
      let fd = new FormData();
      fd.append("id", this.basicInfo.id);
      fd.append("is_collection", 0);
      this.axios.post("/coursepc/index/change_collection", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("取消成功");
          this.getCourseDetailData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 看视频
    toVideo(vid) {
      if (this.courseNumInfo.is_buy === 0) {
        this.$message.error("请先购买课程！");
        return;
      }

      this.$router.push(
        `/courplay?vid=${vid}&cid=${this.basicInfo.id}&ct=${this.basicInfo.type_name}`
      );
    },
    // 立即观看
    continuePlay() {
      this.$router.push(
        `/courplay?vid=${this.courseNumInfo.last_video_id}&cid=${this.basicInfo.id}&ct=${this.basicInfo.type_name}`
      );
    },
    nowPlay() {
      let newId = this.videoList[0].id;
      this.$router.push(
        `/courplay?vid=${newId}&cid=${this.basicInfo.id}&ct=${this.basicInfo.type_name}`
      );
    },
    goExam() {
      // this.$router.push(
      //   `/exampage?examid=${basicInfo.id}&examtitle=${basicInfo.title}`
      // );
      // this.$router.push(
      //   `/exampage?examid=${this.basicInfo.id}&examtitle=${this.basicInfo.title}`
      // );
      // return;
      let fd = new FormData();
      fd.append("course_id", this.basicInfo.id);
      this.axios.post("/coursepc/exam/practice_exam", fd).then((res) => {
        if (res.data.code === 1 || res.data.code === 2 || res.data.code === 3) {
          this.$message.error(res.data.msg);
        } else {
          this.$router.push(
            `/exampage?examid=${this.basicInfo.id}&examtitle=${this.basicInfo.course_name}`
          );
        }
      });
    },
    addCart() {
      let fd = new FormData();
      fd.append("course_id", this.basicInfo.id);
      this.axios.post("/course/pays/add_shop_cart", fd).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
          // setTimeout(() => {
          //   this.$router.push("/login");
          // }, 500);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  min-height: calc(100vh - 80px);
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  & > div > div.details {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
  }
  .details-title {
    height: 277px;
    display: flex;
    align-items: center;
    padding: 0 48px;
    box-sizing: border-box;
    .title-img {
      width: 404px;
      height: 229px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .title-info {
      align-self: flex-start;
      margin-left: 36px;
      margin-top: 52px;
      p {
        margin: 0;
        color: #333333;
      }
      & > p:first-child {
        font-size: 24px;
        font-weight: bold;
      }
      & > p:nth-child(2) {
        font-size: 16px;
        margin-top: 22px;
      }
    }
    .title-progress {
      margin-left: auto;
      align-self: flex-start;
      margin-top: 80px;
      .progress-pro {
        width: 100%;
        span {
          float: left;
          margin-left: 30px;
          margin-top: -8px;
        }
      }
      .progress-btns {
        width: 306px;
        display: flex;
        justify-content: space-between;
        margin-top: 88px;
        button {
          width: 133px;
          height: 43px;
          background: #ffffff;
          border: 1px solid #c6c3c3;
          border-radius: 4px;
          font-size: 16px;
          color: #333;
          cursor: pointer;
        }
        .collection {
          background: url(../assets/images/col_star.png) no-repeat;
          background-position: 26% 150%;
        }
        .now-see {
          background: #2badef;
          border: 0;
          color: #ffffff;
        }
      }
    }
  }
  .details-directory {
    padding: 20px 45px 20px 32px;
    box-sizing: border-box;
    margin-top: 20px;
    .directory-title {
      width: 100%;
      font-size: 18px;
      color: #2badef;
      text-align: center;
      margin: 0;
    }
    .course-catalog-text {
      font-size: 18px;
      color: #333333;
      margin-left: 8px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 18px;
        background: #2badef;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    ul {
      padding: 0;
      margin-top: 16px;
      li {
        list-style-type: none;
        max-width: 657px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        padding: 0 14px;
        box-sizing: border-box;
        cursor: pointer;
        background: url(../assets/images/course_status.png) no-repeat;
        background-position: 96% 120%;
        position: relative;
        img {
          margin-right: 8px;
          position: relative;
          top: 5%;
        }
        &:hover {
          background-color: #fafafa;
        }
        .chapter-nearly {
          font-style: normal;
          font-size: 12px;
          color: #8b8a8a;
          position: absolute;
          right: 8%;
        }
      }
    }
  }
}
</style>
